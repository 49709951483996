import IconLogo from '@/components/_rebrand/IconLogo';
import Shared from '@/components/modals/shared';
import SocialProofContent from '@/components/modals/shared/SocialProofContent';
import { FC, useState } from 'react';
import Styled from './components';
import InitialForm, { InitialFormValues } from './InitialForm';
import SecondaryForm from './SecondaryForm';

const TOTAL_STEPS = 2;

const QUOTES = [
  {
    quote:
      'My team literally could not do what they do without Front. With one tool to manage hundreds of partners and thousands of accounts, Front gives our team the ability to work efficiently and deliver a great experience.',
    name: 'Trish Bingham',
    title: 'Vice President, Client Services, Boostability',
    image: '/assets/authors/trish-bingham.png',
  },
  {
    quote:
      'Using Front has allowed us to consistently keep fantastic support in an ever-changing company, across channels, and across time zones.',
    name: 'Jarratt Isted',
    title: 'Co-founder, HelpDocs',
    image: '/assets/authors/jarratt-isted.png',
  },
];

interface SignupModalProps {
  preventClose?: boolean;
  onClose?: () => void;
  step1Heading?: string;
  step1Subheading?: string;
  step2Heading?: string;
  step2Subheading?: string;
}

export interface SignupModalStep {
  heading?: string;
  subheading?: string;
}

const SignupModal: FC<SignupModalProps> = ({
  preventClose = false,
  onClose = () => { },
  step1Heading = '14-days free, no credit card required',
  step1Subheading = 'You’re one step closer to delivering exceptional service at scale.',
  step2Heading = 'Almost there!',
  step2Subheading = 'Help us tailor the getting started experience to your needs.',
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [formValues, setFormValues] = useState<InitialFormValues | undefined>();

  return (
    <Styled.Modal name="Free Trial Modal" preventClose={preventClose} sendEvents={!preventClose} onClose={onClose}>
      <Shared.ModalWrapper>
        <Shared.FormColumn>
          <IconLogo width={75} height={25} />
          {currentStep === 1 && (
            <InitialForm
              heading={step1Heading}
              subheading={step1Subheading}
              onFormSuccess={(initialFormValues) => {
                setFormValues(initialFormValues);
                setCurrentStep(2);
              }}
            />
          )}

          {currentStep === 2 && (
            <SecondaryForm
              onClose={onClose}
              heading={step2Heading}
              subheading={step2Subheading}
              suggestDemo={!preventClose}
              initialFormValues={formValues!}
            />
          )}
        </Shared.FormColumn>

        <SocialProofContent />
      </Shared.ModalWrapper>
    </Styled.Modal>
  );
};

export default SignupModal;
