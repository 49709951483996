import { ReactComponent as GearIcon } from '@/assets/images/svg/glyphs/gear.svg';
import { ReactComponent as WarningIcon } from '@/assets/images/svg/glyphs/warning.svg';
import InputFieldElement from '@/components/forms/InputFieldElement';
import { COLORS } from '@/styles/color';
import { FONT_WEIGHTS, TEXT_SIZES } from '@/styles/typography';
import { remCalc } from '@/styles/typography/utils';
import { ChangeEvent, FocusEvent, KeyboardEvent, forwardRef, useId, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputFieldElementWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

export const InputFieldIconWrapper = styled.div`
  position: absolute;
  right: ${remCalc(8)};
  width: ${remCalc(16)};
  pointer-events: none;

  svg {
    color: ${COLORS.RED.LOWLIGHT};
  }
`;

export const InputFieldLabel = styled.label`
  ${TEXT_SIZES[16]};

  display: inline-block;
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  padding-bottom: ${remCalc(8)};
  align-self: flex-start;
`;

const spin = keyframes`
  from { transform:rotate(0deg); }
  to { transform:rotate(360deg); }
`;

const StyledGearIcon = styled(GearIcon)`
  animation-name: ${spin};
  animation-duration: 1.75s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  path {
    fill: ${COLORS.BLACK};
  }
`;

export const InputFieldMessage = styled.span`
  ${TEXT_SIZES[13]};
  margin-top: ${remCalc(6)};
  color: ${COLORS.GRAY.BASE};
`;

export interface InputFieldProps extends PropsWithClassName {
  name: string;
  type: 'text' | 'email' | 'password' | 'search';
  label?: string;
  placeholder?: string;
  invalid?: boolean;
  errorMessage?: string;
  warningMessage?: string;
  helpMessage?: string;
  value?: string;
  translucent?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onValueChange?: (value: string) => void;
  onEnterKey?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const id = useId();

  const isInvalid = props.invalid && !!props.errorMessage;
  const hasWarning = !isInvalid && !!props.warningMessage;

  const icon = useMemo(() => {
    if (props.invalid) return <WarningIcon />;
    if (props.loading) return <StyledGearIcon />;

    return null;
  }, [props]);

  return (
    <InputFieldWrapper className={props.className}>
      {props.label && <InputFieldLabel htmlFor={id}>{props.label}</InputFieldLabel>}

      <InputFieldElementWrapper>
        <InputFieldElement
          ref={ref}
          id={id}
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={(e) => {
            if (props.onChange) props.onChange(e);
            if (props.onValueChange) props.onValueChange(e.target.value);
          }}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          invalid={props.invalid}
          translucent={props.translucent}
          disabled={props.disabled}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && props.onEnterKey) {
              props.onEnterKey(e);
            }
          }}
        />

        {icon && <InputFieldIconWrapper>{icon}</InputFieldIconWrapper>}
      </InputFieldElementWrapper>

      {isInvalid ? (
        <InputFieldMessage style={{ color: COLORS.RED.LOWLIGHT }}>{props.errorMessage}</InputFieldMessage>
      ) : hasWarning ? (
        <InputFieldMessage style={{ color: COLORS.ORANGE.LOWLIGHT }}>{props.warningMessage}</InputFieldMessage>
      ) : !!props.helpMessage ? (
        <InputFieldMessage>{props.helpMessage}</InputFieldMessage>
      ) : null}
    </InputFieldWrapper>
  );
});

InputField.displayName = 'InputField';

export default InputField;
